import { gql, useQuery } from '@apollo/client';

const CountryField = ({
    value,
    onChange,
}: { value: string; onChange: (value: string) => void }) => {
    const {
        loading,
        data: { countries } = {
            countries: [],
        },
    } = useQuery<{ countries: { code: string; name: string }[] }>(gql`
        {
            countries {
                code
                name
            }
        }
    `);
    if (loading) {
        return null;
    }
    return (
        <div className="row mb-3 form-group">
            <div className="col-md-4 col-form-label">
                <label htmlFor="sex">Country</label>
            </div>
            <div className="col-md-8">
                <select
                    value={value}
                    onChange={event => {
                        onChange(event.target.value);
                    }}
                    className="form-control"
                >
                    <option>{''}</option>
                    {countries
                        .slice()
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(({ code, name }) => (
                            <option value={code} key={code}>
                                {name}
                            </option>
                        ))}
                </select>
            </div>
        </div>
    );
};

export default CountryField;
