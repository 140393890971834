import { ApolloProvider } from '@apollo/client';
import { createRoot } from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom';
import { client } from './client';
import App from './component/App';

// biome-ignore lint/style/noNonNullAssertion: <explanation>
createRoot(document.getElementById('root')!).render(
    <ApolloProvider client={client}>
        <Router>
            <App />
        </Router>
    </ApolloProvider>,
);
