import type { ReactNode } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './Layout.scss';

interface Props {
    children: ReactNode;
}

const Layout = ({ children }: Props) => (
    <div className="container Layout">{children}</div>
);

export default Layout;
