import { useQuery } from '@apollo/client';
import { Link, Route, Switch } from 'react-router-dom';
import parseKey from '../domain/parseKey';
import type { GuestCheckinResult } from '../types';
import CheckinForm from './CheckinForm';
import Home from './Home';
import Layout from './common/Layout';
import SpinnerIcon from './common/SpinnerIcon';
import guestCheckinQuery from './gql/guestCheckinQuery';

const App = () => {
    /* eslint no-restricted-globals: 0 */
    const id = parseKey(new URLSearchParams(location.search).get('key') || '')[1];
    const { error, data, loading } = useQuery<{ booking: GuestCheckinResult }>(guestCheckinQuery, {
        variables: { id: +id },
        skip: !id,
    });

    if (error) {
        return (
            <Layout>
                <div className="text-center pt-5">
                    <div className="alert alert-danger" role="alert">
                        Error loading guest checkin form, please contact the host.
                    </div>
                </div>
            </Layout>
        );
    }
    if (!data || loading) {
        return (
            <Layout>
                <div className="text-center pt-5">
                    <SpinnerIcon />
                </div>
            </Layout>
        );
    }

    const { booking } = data;

    return (
        <Layout>
            <div style={{ margin: '1rem 0' }}>
                <h2>Your booking at Budapest Dob 51</h2>
            </div>

            {!booking.detailsRecorded && (
                <div style={{ margin: '1rem 0' }}>
                    <ul className="list-group">
                        <li className="list-group-item">
                            {!booking.detailsRecorded && (
                                <Switch>
                                    <Route path="/guest-checkin">
                                        <strong>Enter the guest details</strong>
                                    </Route>
                                    <Route>
                                        <p>Enter the guest details</p>
                                        <p>
                                            <Link to="/guest-checkin" className="btn btn-primary">
                                                Enter details
                                            </Link>
                                        </p>
                                    </Route>
                                </Switch>
                            )}
                        </li>
                    </ul>
                </div>
            )}
            <Switch>
                <Route path="/guest-checkin">
                    <CheckinForm data={booking} />
                </Route>
                <Route>
                    <Home booking={booking} />
                </Route>
            </Switch>
        </Layout>
    );
};

export default App;
