import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
    FontAwesomeIcon,
    type FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

const SpinnerIcon = (props: Partial<FontAwesomeIconProps>) => (
    <FontAwesomeIcon spin icon={faSpinner} size="5x" {...props} />
);

export default SpinnerIcon;
